<template>
  <div class="container-fluid page-body-wrapper">
    <div class="flex items-center justify-center">
      <div class="w-full lg:w-1/2 bg-gray-100 p-8 rounded-lg">
        <div v-if="loading" class="text-center">
          <i class="fas fa-spinner fa-spin"></i> Loading...
        </div>
        <div v-else>
          <div v-if="product" class="border rounded p-4">
            <div class="bg-gray-200 p-4 rounded mb-4">
              <h1 class="text-2xl font-bold">Request for Quotation</h1>
            </div>
            <div class="flex flex-col lg:flex-row items-center">
              <div class="mb-4 lg:mr-4 lg:mb-0">
                <h1 class="text-2xl font-bold">{{ product.name }}</h1>
              </div>
              <div v-if="product.images && product.images.length > 0">
                <img :src="product.images[0].src" alt="Product Image" class="w-32 h-32 object-cover rounded" />
              </div>
            </div>
            <!-- Request for Quotation Form -->
            <form @submit.prevent="sendRequest" class="bg-white p-6 rounded shadow-md mt-4">
              <div class="mb-4">
                <label for="name" class="block text-sm font-semibold text-gray-600">Your Name:</label>
                <input v-model="name" type="text" id="name" class="w-full p-2 border rounded" placeholder="Enter your name" required />
              </div>
              <div class="mb-4">
                <label for="email" class="block text-sm font-semibold text-gray-600">Your Email:</label>
                <input v-model="email" type="email" id="email" class="w-full p-2 border rounded" placeholder="Enter your email" required />
              </div>
              <div class="mb-4">
                <label for="message" class="block text-sm font-semibold text-gray-600">Message:</label>
                <textarea v-model="message" id="message" class="w-full p-2 border rounded" placeholder="Enter your message" rows="4" required></textarea>
              </div>
              <div>
                <button :disabled="sendingRequest" type="submit" class="bg-green-500 text-white p-2 rounded">
                  <span v-if="!sendingRequest">Send Request</span>
                  <span v-else>
                    <i class="fas fa-spinner fa-spin"></i> Sending...
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div v-if="successMessage" class="bg-green-500 text-white p-2 rounded mt-4">
            <p>{{ successMessage }}</p>
            <router-link :to="{ name: 'Success' }"></router-link>
          </div>
          <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 p-2 rounded mt-4">
            <p>{{ error }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      product: null,
      loading: false,
      sendingRequest: false,
      successMessage: null,
      error: null,
      name: '',
      email: '',
      message: '',
      apiKey: 'd8940b06-ac1a-4f88-bc9c-ca15399090af',  // Replace with your actual API key
    };
  },
  created() {
    const productSku = this.$route.params.sku;
    const consumerKey = 'ck_56adedf25545d95de123a144a5cee25626786157';
    const consumerSecret = 'cs_e64d83299594d55cca17d271c807cd3ba9b96772';
    const storeUrl = 'https://www.ikimura.co.id';
    const apiEndpoint = `${storeUrl}/wp-json/wc/v3/products?sku=${productSku}`;

    this.loading = true;

    this.$axios.get(apiEndpoint, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    })
      .then(response => {
        if (response.data && response.data.length > 0) {
          this.product = response.data[0];
        } else {
          this.error = "Error: Unable to retrieve product information.";
        }
      })
      .catch(error => {
        console.error("Error fetching product:", error);
        this.error = "Error: Unable to retrieve product information.";
      })
      .finally(() => {
        this.loading = false;
      });
      document.title = 'RFQ - Ikimura ' ;
  },
  methods: {
    sendRequest() {
      this.sendingRequest = true;

      this.$axios.post('https://kwg88cs.cloud.app.ikimura.co.id/send-email', {
        name: this.name,
        email: this.email,
        message: this.message,
        product_name: this.product.name,
      }, {
        headers: {
          'Authorization': `${this.apiKey}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          console.log(response.data);
          if (response.data.success) {
            this.successMessage = 'Request sent successfully!';
            this.$router.push({ name: 'Success' }); // Replace 'targetRoute' with your route name
          } else {
            alert('Error sending request. Please try again later.');
          }
        })
        .catch(error => {
          console.error('Error sending request:', error);
          alert('Error sending request. Please try again later.');
        })
        .finally(() => {
          this.sendingRequest = false;
        });
    },
  },
};
</script>
