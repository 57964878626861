// src/main.js

import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
// src/main.js
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/css/tailwind.css'; // Import the tailwind.css file
import './assets/css/main.css';

Vue.config.productionTip = false;

// Set up Axios globally
Vue.prototype.$axios = axios;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
