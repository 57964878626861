<template>
    <div>
     <head>
      <title>RFQ Submission</title>
    </head>
    <div class="success-container">
      <div class="success-content">
        <div class="success-message">
          <h1>RFQ Accepted!</h1>
          <p>Thank you for submitting your RFQ. Your quotation request has been received. We will contact you shortly.</p>
        </div>
        <div class="success-image">
          <a href="https://ikimura.co.id"  rel="noopener">Back to Website</a>
        </div>
      </div>
    </div>
</div>
  </template>
  <script>
  export default {
    data() {
      return {
        rfqAccepted: true, // Replace with your RFQ status logic
      };
    },
    created() {
      // Set a custom title when the component is created
      document.title = this.rfqAccepted ? 'RFQ Accepted!' : 'RFQ Submission';
    },
  };
  </script>
  
  <style scoped>
  .success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 100% of the viewport height */
  }
  
  .success-content {
    text-align: center;
  }
  
  .success-message {
    padding: 20px;
    background-color: #4CAF50; /* Example: Green background color */
    color: white;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Message box shadow */
  }
  
  .success-image a {
    color: #4CAF50; /* Example: Green color for the link */
    text-decoration: none;
    font-weight: bold;
    display: block; /* Make the link a block element to add margin */
    margin-top: 20px; /* Adjust the top margin as needed */
  }
  
  /* Responsive adjustments */
  @media only screen and (max-width: 600px) {
    .success-message {
      font-size: 16px; /* Decrease font size for smaller screens */
    }
  }
  </style>
  